import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { FieldType } from '@ngx-formly/core';
import { subscribe } from 'diagnostics_channel';
import { Observable, map, of, startWith, switchMap } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-autocomplete-chip-type',
  templateUrl: './autocomplete-chip-type.component.html',
  styleUrl: './autocomplete-chip-type.component.scss',

})
export class AutocompleteChipTypeComponent extends FieldType {
  chipListValues: { value: string, label: string }[] = [];
  filter!: Observable<any>;
  typeLabelProp: string = 'string';
  labelProp: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  defaultValueModel: any[] = []

  /*  -------------------------------- 1. STATE & EVENT --------------------------------  */
  @ViewChild('chipInput') chipInput!: ElementRef<HTMLInputElement>;

  ngOnInit() {

    this.filter = this.formControl.valueChanges.pipe(
      startWith(''),
      switchMap((term) => {
        return this.props['filter'](term)
      }),
    );
    // Save Default Value - And disabled them in search 
    if (this.field.model) {
      const property: any = this.field.key;
      this.defaultValueModel = this.field.model[property];
    }
  }

  ngAfterViewInit(): void {
    if (this.field.props?.['labelProp']) {
      this.labelProp = this.field.props?.['labelProp']
      this.typeLabelProp = typeof (this.field.props?.['labelProp'])
    }
  }
  /*  -------------------------------- / 1. STATE & EVENT --------------------------------  */


  /*  -------------------------------- 2. METHODS --------------------------------  */
  getFormControl(): FormControl {
    return this.field.formControl as FormControl;
  }

  /**
   * Return a custom DisplayFn 
   * @param options Data Filtered
   * @returns 
   */
  customDisplayFn(options: any): (id: any) => any {
    return (id: any) => {
      // Return Default Value
      if (this.field.defaultValue == id) {
        if (this.typeLabelProp == 'function') {
          return this.labelProp(id);
        } else {
          // If LabelProp is a String -> item[labelProp]
          if (this.labelProp) {
            return id[this.labelProp]
          } else {
            // Default Case : return element
            return id
          }
        }
      } else {
        const correspondingOption = Array.isArray(options) ? options.find(option => option.id === id) : null;
        // If Find a result
        if (correspondingOption) {
          // If LabelProp is a Function -> Trasnform data
          if (this.typeLabelProp == 'function') {
            return this.labelProp(correspondingOption);
          } else {
            // If LabelProp is a String -> item[labelProp]
            if (this.labelProp) {
              return correspondingOption[this.labelProp]
            } else {
              // Default Case : return element
              return correspondingOption
            }
          }
        }
      }

    }
  }
  /*  -------------------------------- / 2. METHODS --------------------------------  */


  /*  -------------------------------- 3. CHIP - METHODS --------------------------------  */
  // NOT USE
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      //    this.chipListValues.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.field.formControl?.setValue(this.chipListValues);
  }

  remove(chip: any): void {
    const index = this.chipListValues.findIndex(el => el.value = chip.value);
    if (index >= 0) {
      this.chipListValues.splice(index, 1);
      this.field.formControl?.setValue(this.chipListValues.map((item: any) => item.value));
    }
  }


  /**
   * When selecting the autocomplete list, add an item to the chipListValues and update the data in the formControl
   * @param event Value selected in autocomplete
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    this.chipListValues.push({ value: event.option.value, label: event.option.viewValue }); // Add New Element to chip List
    this.chipInput.nativeElement.value = ' '; // Reset Input Search after each add
    let currentFormValue = this.chipListValues.map((item: any) => item.value)
    this.field.formControl?.setValue(currentFormValue); // Update Form Value
  }

  /**
   * Disable option if is in chipListValues ( already selected )
   * @param option 
   * @returns 
   */
  optionAlreadySelected(option: any) {
    const indexChip = this.chipListValues.findIndex(item => item.value == option.id); // Search Option in Selected value
    const indexDefaultValue = this.defaultValueModel.findIndex(item => item.id == option.id); // Search Option in defaultValue
    return (indexChip == -1 && indexDefaultValue == -1) ? false : true;
  }
  /*  -------------------------------- / 3. CHIP - METHODS --------------------------------  */
}


// TODO : ADD DEFAULT VALUE WITH USER FOR SITE 