import { Component, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MedicalDeviceAlertService } from '@app/data/services/medical-device-alert.service';
import { MedicalDeviceBatchService } from '@app/data/services/medical-device-batch.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map } from 'rxjs';

const uudiRegexExp =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
interface AlertAddModel {
  name?: string;
  origine?: string;
  alertDate?: Date;
  registeredDevice?: string;
  registeredDeviceName?: string;
  recipientEmail?: string;
  correctiveActions?: string;
  sentDate?: Date;
  file?: any;
  medicalDeviceBatches?: any[];
}

@Component({
  selector: 'app-accept-medical-device-alert-draft',
  templateUrl: './accept-medical-device-alert-draft.component.html',
  styleUrl: './accept-medical-device-alert-draft.component.scss',
})
export class AcceptMedicalDeviceAlertDraftComponent {
  readonly dialogRef = inject(
    MatDialogRef<AcceptMedicalDeviceAlertDraftComponent>
  );
  readonly data = inject<any>(MAT_DIALOG_DATA);

  deviceAlertAddModel: AlertAddModel = {};
  deviceAlertAddForm = new FormGroup({});
  deviceAlertAddFormFieldConfig: FormlyFieldConfig[] = [
    {
      type: 'flex-layout',
      templateOptions: {
        fxLayout: 'column',
        fxLayoutGap: '20px',
      },
      fieldGroup: [
        {
          type: 'flex-layout',
          templateOptions: {
            fxLayout: 'row',
            fxLayoutGap: '20px',
          },
          fieldGroup: [
            {
              key: 'alertDate',
              type: 'date',
              props: {
                readonly: true,
                required: true,
                label: 'Date de déclaration',
              },
            },
            {
              key: 'origine',
              type: 'string',
              props: {
                label: 'Origine',
                required: true,
                readonly: true,
              },
            },
          ],
        },
        {
          type: 'flex-layout',
          templateOptions: {
            fxLayout: 'row',
            fxLayoutGap: '20px',
          },
          fieldGroup: [
            {
              key: 'registeredDeviceName',
              type: 'input',
              props: {
                label: 'Dispositif médical',
                required: true,
                readonly: true,
              },
            },
            {
              key: 'medicalDeviceBatches',
              type: 'select',
              props: {
                label: 'Lots concernés',
                required: false,
                multiple: true,
              },
              expressions: {
                'props.readonly': 'model.registeredDevice ? false : true',
              },
            },
          ],
        },
        {
          type: 'flex-layout',
          templateOptions: {
            fxLayout: 'row',
            fxLayoutGap: '20px',
          },
          fieldGroup: [
            {
              key: 'name',
              type: 'string',
              props: {
                label: "Nom de l'alerte",
                required: true,
                readonly: true,
              },
            },
            {
              template: '<div></div>',
            },
          ],
        },
        {
          type: 'flex-layout',
          templateOptions: {
            fxLayout: 'row',
            fxLayoutGap: '20px',
          },
          fieldGroup: [
            {
              key: 'recipientEmail',
              type: 'input',
              props: {
                label: 'Email fabricant',
                type: 'email',
                required: true,
              },
              validators: {
                validation: ['email'],
              },
            },
            {
              key: 'sentDate',
              type: 'date',
              props: {
                label: 'Date de réponse',
                required: false,
              },
            },
          ],
        },
        {
          key: 'correctiveActions',
          type: 'textarea',
          props: {
            label: 'Mesures correctives',
            required: true,
            autosize: true,
            autosizeMinRows: 3,
            autosizeMaxRows: 5,
          },
        },
      ],
    },
  ];

  constructor(
    private medicalDeviceBatchService: MedicalDeviceBatchService,
    private medicalDeviceAlertService: MedicalDeviceAlertService
  ) {
    const alert = this.data;

    if (
      !alert ||
      !alert.global_alert ||
      !alert.registered_device ||
      !alert.id
    ) {
      this.dialogRef.close();
    }

    this.deviceAlertAddModel = {
      alertDate: alert.global_alert.alert_date,
      origine: alert.global_alert.origine,
      registeredDevice: alert.registered_device.id,
      registeredDeviceName: alert.registered_device.medical_device.name,
      name: alert.global_alert.name,
      recipientEmail: alert.global_alert.recipient_email,
    };

    // Update the medicalDeviceBatches field options based on the selected registeredDevice
    if (
      this.deviceAlertAddFormFieldConfig[0] &&
      this.deviceAlertAddFormFieldConfig[0].fieldGroup &&
      this.deviceAlertAddFormFieldConfig[0].fieldGroup[1] &&
      this.deviceAlertAddFormFieldConfig[0].fieldGroup[1].fieldGroup &&
      this.deviceAlertAddFormFieldConfig[0].fieldGroup[1].fieldGroup[1] &&
      this.deviceAlertAddFormFieldConfig[0].fieldGroup[1].fieldGroup[1].props
    ) {
      const props =
        this.deviceAlertAddFormFieldConfig[0].fieldGroup[1].fieldGroup[1].props;
      props.options = this.medicalDeviceBatchService
        .getAllForRegisteredDeviceWithBatchNumberFilter(
          alert.registered_device.id
        )
        .pipe(
          map((data: any) => {
            return data.map((d: any) => ({
              ...d,
              value: d.id,
              label: d.batch_number,
            }));
          })
        );
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  acceptDraft() {
    if (this.deviceAlertAddForm.valid) {
      this.medicalDeviceAlertService
        .acceptDraft(this.data.id, this.deviceAlertAddModel)
        .subscribe({
          next: (data) => {
            this.dialogRef.close(data);
          },
          error: (error) => {
            this.dialogRef.close();
          },
        });
    }
  }
}
