import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  endpoint: string = environment.apiURL;

  constructor(private http: HttpClient, private _snackBar: MatSnackBar) {}

  /**
   * Global Getter
   * @param path API route
   * @returns Observable
   */
  getAPIGlobal(path: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/${path}`);
  }

  getApiGlobalWithSingleFilter(path: string, filter: any): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/${path}?filter.${filter}`);
  }
  getApiGlobalWithFilters(
    path: string,
    filter: any,
    search?: string
  ): Observable<any> {
    return this.http.get<any>(
      `${this.endpoint}/${path}?filter.${filter}${
        '&search=' + (search ? search : '')
      }`
    );
  }

  /**
   * Global Getter
   * @param path API route
   * @returns Observable
   */
  getAPIGlobalPaginated(
    path: string,
    page: number,
    limit?: number,
    search?: string,
    sort?: string,
    filter?: string
  ): Observable<any> {
    return this.http.get<any>(
      `${this.endpoint}/${path}${path.includes('?filter.') ? '&' : '?'}${
        'page=' + (page + 1) + '&'
      }${'search=' + (search ? search : '')}${
        '&limit=' + (limit ? limit : 20)
      }${'&sortBy=' + sort}&filter.${filter}`
    );
  }

  /**
   * Global Create
   * @param path API route
   * @param item Item to create
   * @returns Observable
   */
  createAPIGlobal(path: string, item: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${path}`, item);
  }

  /**
   * Global Update
   * @param path API route
   * @param item Item to update
   * @param id ID of item
   * @returns Observable
   */
  updateAPIGlobal(path: string, id: any, item: any): Observable<any> {
    return this.http.patch<any>(`${this.endpoint}/${path}/${id}`, item);
  }

  /**
   * Global Delete
   * @param path API route
   * @param id ID of item to delete
   * @returns Observable
   */
  deleteAPIGlobal(path: string, id: any): Observable<any> {
    return this.http.delete<any>(`${this.endpoint}/${path}/${id}`);
  }

  /**
   *
   * @param path API route
   * @param id ID of entity
   * @param file File to upload
   * @returns
   */
  createImageForEntity(path: string, id: any, file: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${path}/${id}`, file);
  }

  /**
   *
   * @param path API route
   * @param id ID of entity
   * @param file File to upload
   * @returns
   */
  updateAvatar(path: string, id: any, file: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${path}/avatar/${id}`, file);
  }

  /**
   * Triggers the display of a pop up message
   * @param message Message display ex. 'Hello world !'
   * @param action  Message display in close btn
   */
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 2 * 1000,
    });
  }
}
