import { Component, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-addons',
  template: `
    <ng-template #matPrefix>
      <span
      *ngIf="props['addonLeft']"
      [ngStyle]="{ cursor: props['addonLeft'].onClick ? 'pointer' : 'inherit', paddingLeft:'5px', paddingRight:'5px'  }"
        (click)="addonLeftClick($event)"
      >
  
      <!-- Mat Icon -->
      <mat-icon *ngIf="props['addonLeft'].icon" matPrefix> {{props['addonLeft'].icon}}  </mat-icon> 
      <!-- SVG Icon -->
      <mat-icon *ngIf="props['addonLeft'].svg" >
       <svg-icon   [src]="'assets/icons/'+props['addonLeft'].svg+'.svg'" fxLayout="row"  fxLayoutAlign="center center"></svg-icon>
      </mat-icon>
       <!-- Text -->
       <span *ngIf="props['addonLeft'].text">{{ props['addonLeft'].text }}</span>
      </span>
    </ng-template>


    <ng-container #fieldComponent ></ng-container>


    <ng-template #matSuffix>
      <span
        *ngIf="props['addonRight']"
        [ngStyle]="{ cursor: props['addonRight'].onClick ? 'pointer' : 'inherit',paddingLeft:'5px',paddingRight:'10px' } "
        (click)="addonRightClick($event)"
      >
        <!-- Mat Icon -->
        <mat-icon *ngIf="props['addonRight'].icon" style="color: currentColor;">{{ props['addonRight'].icon }}</mat-icon>
        <!-- SVG Icon -->
        <svg-icon  *ngIf="props['addonRight'].svg"  [src]="'assets/icons/'+props['addonRight'].svg+'.svg'" fxLayout="row" fxLayoutAlign="center center"></svg-icon>
        <!-- Text -->
        <span *ngIf="props['addonRight'].text">{{ props['addonRight'].text }}</span>
      </span>
    </ng-template>
  `,
  styles: [
    `
    ::ng-deep{

      .mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper{
       // padding-left:16px;
       // BUG : Bug avec la nouvelle version d'ANgular Material, affecte quand même un padding-left de -16px alors qu'il n'y a pas d'icon du au fait que le #matPrefix fait croire au code qu'il y a une icône
      }

      .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper{
        padding-right: 0;
      }

    }
    `
  ]
})
export class FormlyWrapperAddons extends FieldWrapper implements AfterViewInit {
  @ViewChild('matPrefix', { static: true }) matPrefix!: TemplateRef<any>;
  @ViewChild('matSuffix', { static: true }) matSuffix!: TemplateRef<any>;

  ngAfterViewInit() {
    if (this.matPrefix) {
      // Note: for text use `textPrefix` property (only in Angular Material >= 15)
      this.props['prefix'] = this.matPrefix;
    }

    if (this.matSuffix) {
      // Note: for text use `textSuffix` property (only in Angular Material >= 15)
      // Note: for text use `textSuffix` property (only in Angular Material >= 15)
      this.props['suffix'] = this.matSuffix;
    }
  }

  addonRightClick($event: any) {
    if (this.props['addonRight'].onClick) {
      this.props['addonRight'].onClick(this.to, this, $event);
    }
  }

  addonLeftClick($event: any) {
    if (this.props['addonLeft'].onClick) {
      this.props['addonLeft'].onClick(this.to, this, $event);
    }
  }


}