<mat-form-field style="width: 100%">
  <mat-label>{{ to["label"] }}</mat-label>
  <mat-icon matPrefix>
    <svg-icon
      [src]="to['icon']"
      fxLayout="row"
      fxLayoutAlign="center center"
      [svgStyle]="{ 'height.px': 22, 'width.px': 22 }"
    ></svg-icon>
  </mat-icon>
  <input
    matInput
    [formControl]="getFormControl()"
    [formlyAttributes]="field"
    [readonly]="to.readonly"
    [placeholder]="
      to.placeholder
        ? to.required
          ? to.placeholder + ' *'
          : to.placeholder
        : ''
    "
    [required]="to.required ?? false"
  />

  <mat-error>
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error>

  <mat-hint *ngIf="to.description">
    {{ to.description }}
  </mat-hint>
</mat-form-field>
