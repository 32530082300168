import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';


@Component({
  selector: 'formly-field-divider',
  template: `
   <hr>
  `, styles: [
    `
    hr{
      display: block;
      margin: 20px 0px;
      border:none;
      border-top-width: 1px;
      border-top-style: solid;
          border-top-color: rgba(0, 0, 0, 0.12);
      }
    `
  ]
})
export class DividerFieldType extends FieldType<FieldTypeConfig> { }