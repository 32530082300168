import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-file',
    template: `

    <span *ngIf="to['label']" class="label">{{to['label']}}</span>
        <div class="flex-row">
           <div *ngFor="let image of files; let i = index" class="image-preview">
                 <img [src]="image.url" alt="Image prévisualisée" crossorigin="anonymous" height="50px" width="50px">
            </div>
            <div class="image-preview" *ngIf="files.length==0&&field.formControl?.value">
             <img [src]="field.formControl?.value" crossorigin="anonymous" height="50px" width="50px">
            </div>
            <input type="file" (change)="onFileSelected($event)" [accept]="to['accept']" [formlyAttributes]="field" class="inputfile" class="w-100" />
        </div>
 
  `,
    styles: [
        `
        .label{
            font-size:15px;
            color:#000;
            margin-left:5px;
        }
        .flex-row{
            display:flex;
            justify-content:flex-start;
            align-items:center;
            gap:10px;
            background-color: whitesmoke;
            padding: 7px 15px;
            border-radius:16px;
            margin-bottom:22px;
            margin-top: 5px;
        }

        /* file upload button */
        input[type="file"]::file-selector-button {
        border-radius: 4px;
        padding: 0 16px;
        height: 40px;
        cursor: pointer;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.16);
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        margin-right: 16px;
        transition: background-color 200ms;
        }

        /* file upload button hover state */
        input[type="file"]::file-selector-button:hover {
        background-color: #f3f4f6;
        }

        /* file upload button active state */
        input[type="file"]::file-selector-button:active {
        background-color: #e5e7eb;
        }

        img{
            border-radius:5px;
        }
    `,
    ],
})
export class FormlyFieldFile extends FieldType {

    files: any[] = [];

    constructor(private sanitizer: DomSanitizer) {
        super();
    }

    ngOnInit() {
        // Set the default value for the form control

    }

    getFormControl(): FormControl {
        return this.field.formControl as FormControl;
    }


    onFileSelected(event: Event) {
        const files: any = (event.target as HTMLInputElement).files;
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(files[i]));
                files[i]['url'] = url
                this.files.push(files[i]);
            }
        }
        this.field.formControl?.setValue(this.files); // Mettez à jour le tableau d'images dans le FormControl
    }

}