import { Component, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MedicalDeviceAlertService } from '@app/data/services/medical-device-alert.service';
import { FormlyFieldConfig } from '@ngx-formly/core';

const uudiRegexExp =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
interface UpdateAlert {
  id?: string;
  correctiveActions?: string;
}

@Component({
  selector: 'app-update-alert-corrective-actions',
  templateUrl: './update-alert-corrective-actions.component.html',
  styleUrl: './update-alert-corrective-actions.component.scss',
})
export class UpdateAlertCorrectiveActionsComponent {
  readonly dialogRef = inject(
    MatDialogRef<UpdateAlertCorrectiveActionsComponent>
  );
  readonly data = inject<any>(MAT_DIALOG_DATA);

  updateAlertModel: UpdateAlert = {};
  deviceAlertAddForm = new FormGroup({});
  deviceAlertAddFormFieldConfig: FormlyFieldConfig[] = [
    {
      key: 'correctiveActions',
      type: 'textarea',
      props: {
        label: 'Mesures correctives',
        required: true,
        autosize: true,
        autosizeMinRows: 3,
        autosizeMaxRows: 5,
      },
    },
  ];

  constructor(private medicalDeviceAlertService: MedicalDeviceAlertService) {
    const alert = this.data;

    if (!alert || !alert.id) {
      this.dialogRef.close();
    }

    this.updateAlertModel = {
      id: alert.id,
      correctiveActions: alert.corrective_actions,
    };
  }

  cancel() {
    this.dialogRef.close();
  }

  acceptDraft() {
    if (this.deviceAlertAddForm.valid) {
      this.medicalDeviceAlertService.update(this.updateAlertModel).subscribe({
        next: (data) => {
          this.dialogRef.close(data);
        },
        error: (error) => {
          this.dialogRef.close();
        },
      });
    }
  }
}
