import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrl: './file-picker.component.scss',
})
export class FilePickerComponent extends FieldType {
  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  files: any[] = [];
  icon: string = '/assets/icons/no-document-light.svg';

  inputText: string | undefined;

  ngOnInit(): void {}

  getFormControl(): FormControl {
    return this.field.formControl as FormControl;
  }

  pickFile(event: any): void {
    const file = event.target.files[0];
    this.getFormControl().setValue(file);
  }

  onFileSelected(event: Event) {
    const files: any = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      this.icon = '/assets/icons/document-light.svg';
      this.inputText =
        files.length > 1
          ? `${files.length} fichiers sélectionnés`
          : files[0].name;
      for (let i = 0; i < files.length; i++) {
        const url = this.sanitizer.bypassSecurityTrustUrl(
          window.URL.createObjectURL(files[i])
        );
        files[i]['url'] = url;
        this.files.push(files[i]);
      }
    } else {
      this.icon = '/assets/icons/no-document-light.svg';
    }
    this.field.formControl?.setValue(this.files); // Mettez à jour le tableau d'images dans le FormControl
  }

  deleteFiles() {
    this.files = [];
    this.icon = '/assets/icons/no-document-light.svg';
    this.inputText = undefined;
    this.field.formControl?.setValue(this.files);
  }
}
