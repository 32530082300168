<mat-form-field style="width: 100%">
  <mat-label>{{ to["label"] }}</mat-label>

  <mat-error>
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error>

  <mat-checkbox
    *ngFor="let option of opts"
    [value]="option.value"
    (change)="checkboxChange(option, $event)"
    [checked]="optionChecked(option)"
  >
    {{ option.label }}
  </mat-checkbox>
  <input style="display: none" matInput />

  <mat-hint *ngIf="to.description">
    {{ to.description }}
  </mat-hint>
</mat-form-field>

@if(showComment){
<div style="height: 10px"></div>
<mat-form-field style="width: 100%">
  <mat-label>Commentaire</mat-label>
  <input
    matInput
    type="text"
    [id]="key.toString() + '_comment'"
    [value]="comment"
  />
</mat-form-field>
}
