import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-stepper-type',
  templateUrl: './stepper-type.component.html',
  styleUrl: './stepper-type.component.scss',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class StepperTypeComponent extends FieldType {

  isValid(field: FormlyFieldConfig): boolean {
    if (field.key) {
      if (field.formControl) {
        return field.formControl.valid;
      }
      else return false;
    }
    return field.fieldGroup ? field.fieldGroup.every((f) => this.isValid(f)) : true;
  }

  isStepValid(step: FormlyFieldConfig): boolean {
    return this.isValid(step);
  }

  getStepControl(step: FormlyFieldConfig): any {
    // Vérifier que step.fieldGroup n'est pas vide
    // et retourner le contrôle du premier champ du groupe

    return step.fieldGroup && step.fieldGroup.length > 0 ? step.fieldGroup[0].formControl : undefined;
  }

  cancelForm() {
    this.options.formState.cancel = !this.options.formState.cancel;
    history.back();
  }

}
