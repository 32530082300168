import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Observable, map } from 'rxjs';

interface OptionsBuilder {
  typeBuild?: string
  valueProp?: string
  labelProp?: Function
  translate?: Boolean
  idItem?: any
}

@Injectable({
  providedIn: 'root'
})
export class FormlyBuilderService {

  endpoint: string = environment.apiURL;

  constructor(private http: HttpClient, private cookieService: SsrCookieService) { }

  setUpHooks(fields: FormlyFieldConfig[], fieldName: string, api: string, options: OptionsBuilder /*typeBuildOption: string = 'basic', customValueProp: string = "id", labelProp?: any*/) {
    const { typeBuild, valueProp, labelProp, translate, idItem } = options;

    fields.forEach((field) => {

      // If it has an FieldGroup -> recursive
      if (field.fieldGroup) {
        this.setUpHooks(field.fieldGroup, fieldName, api, options);
      }

      // If it has an onInit
      if ((field.type == 'select' || field.type == 'select-with-icon') && field.key == fieldName && field.props/*&& field.hooks && field.hooks.onInit*/) {

        // If LABELPROP affect it to field config
        if (labelProp) {
          if (translate) {
            const lang = this.cookieService.get('current-language') || 'fr';
            field.props['labelProp'] = (row: any) => labelProp(row)[lang]
          } else {
            field.props['labelProp'] = labelProp
          }
        }

        if (field.key) {
          switch (typeBuild) {

            case 'basic':
              this.buildBasicSelectOption(field, api);
              break;

            case 'id':
              this.buildIdSelectOption(field, api);
              break;

            case 'singleValue':
              this.buildSingleValueSelectOption(field, api, idItem);
              break;

            case 'custom':
              if (valueProp)
                this.buildCustomSelectOption(field, api, valueProp);
              break;

            default:
              break;

          }
        }
      }


    });

    return fields;
  }

  /* ------------------------ 1. Select Builder ------------------------ */

  buildBasicSelectOption(field: any, api: string,) {
    this.getAPIGlobal(api).subscribe(data => {
      if (field.props.options.length > 0) {
        field.props.options = field.props.options.concat(data);
      } else {
        field.props.options = data;
      }
      field.props.valueProp = (option: any) => option
    });
  }

  buildIdSelectOption(field: any, api: string,) {
    this.getAPIGlobal(api).subscribe(data => {
      if (field.props.options.length > 0 && field.props.options[0].id === -1) {
        field.props.options = field.props.options.concat(data);
      } else {
        field.props.options = data;
      }

      field.props.valueProp = (option: any) => option.id
      field.props.compareWith = (o1: any, o2: any) => o2.id ? o1 === o2.id : o1 === o2;
    });
  }


  buildSingleValueSelectOption(field: any, api: string, idItem: any) {
    this.getAPIGlobalSingle(api, idItem).subscribe(data => {

      // Check is data in Array
      if (!Array.isArray(data)) data = [data]


      if (field.props.options.length > 0 && field.props.options[0].id === -1) {
        field.props.options = field.props.options.concat(data);
      } else {
        field.props.options = data;
      }

      field.props.valueProp = (option: any) => option.id
      field.props.compareWith = (o1: any, o2: any) => o2.id ? o1 === o2.id : o1 === o2;
    });
  }

  buildCustomSelectOption(field: any, api: string, custom: string) {
    this.getAPIGlobal(api).subscribe(data => {
      field.props.options = data;
      field.props.valueProp = (option: any) => option[custom.toString()]
    });
  }

  /* ------------------------ / 1. Select Builder ------------------------ */


  /* ------------------------ 2. Bind expressions ------------------------ */

  setUpExpressions(fields: FormlyFieldConfig[], fieldName: string, expression: string, expressionFunction: Function) {
    fields.forEach((field) => {
      // If it has an FieldGroup -> recursive
      if (field.fieldGroup) {
        this.setUpExpressions(field.fieldGroup, fieldName, expression, expressionFunction);
      }
      if (field.key === fieldName) {
        field = this.setExpressionToField(field, expression, expressionFunction);
      }
    });
    return fields;
  }

  /**
  * Cache un champ sous certaines conditions
  *
  * @param field Le FormlyFieldConfig contenant le champ à cacher
  * @param expression Contient la valeur de l'expression ( Ex: hide )
  * @param expressionFunction L'expression qui détermine si le champ doit être caché
  */
  setExpressionToField(field: FormlyFieldConfig, expression: string, expressionFunction: any) {
    if (field.expressions) {
      field.expressions = {}
      field.expressions[expression.toString()] = expressionFunction;
    }
    return field;
  }


  /* ------------------------ / 2. Bind expressions ------------------------ */

  /**
  * Global Getter
  * @param path API route
  * @returns Observable
  */
  getAPIGlobal(path: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/${path}`).pipe(
      map(data => {
        return data['data'] ?? data;
      }
      ));
  }

  /**
* Global Getter
* @param path API route
* @returns Observable
*/
  getAPIGlobalSingle(path: string, id: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/${path}/${id}`).pipe(
      map(data => {
        return data['data'] ?? data;
      }
      ));
  }

}
