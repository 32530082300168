<div
  fxLayout="row"
  fxLayoutAlign="center"
  fxLayoutGap="0px"
  class="page-not-found-container"
>
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <svg-icon
      class="mat-warn-color"
      [src]="'assets/icons/search-not-found-light.svg'"
      fxLayout="row"
      fxLayoutAlign="center center"
      [svgStyle]="{ 'width.px': 150, 'height.px': 150 }"
    ></svg-icon>
    <h1 class="mat-warn-color">Ooups, page introuvable</h1>
    <p class="mat-grey-color">
      Nous sommes désolés pour la gêne occasionnée. Il semble que vous essayez
      d'accéder à une page qui n'existe pas.
    </p>
    <button mat-flat-button color="warn" (click)="goToHomePage()">
      Retour à la page d'accueil
    </button>
  </div>
</div>
