import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { Observable, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'app-autocomplete-type',
  templateUrl: './autocomplete-type.component.html',
  styleUrl: './autocomplete-type.component.scss',
})
export class AutocompleteTypeComponent extends FieldType {
  filter!: Observable<any>;
  typeLabelProp: string = 'string';
  labelProp: any;
  valueProp: any;

  /*  -------------------------------- 1. STATE & EVENT --------------------------------  */
  ngOnInit() {
    this.filter = this.formControl.valueChanges.pipe(
      startWith(''),
      switchMap((term) => {
        if (this.props?.['filter']) return this.props['filter'](term);
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.field.props?.['labelProp']) {
      this.labelProp = this.field.props?.['labelProp'];
      this.typeLabelProp = typeof this.field.props?.['labelProp'];
    }
    if (this.field.props?.['valueProp']) {
      this.valueProp = this.field.props?.['valueProp'];
    }
  }
  /*  -------------------------------- / 1. STATE & EVENT --------------------------------  */

  /*  -------------------------------- 2. DATA MANAGEMENT --------------------------------  */
  transformResponseDataWithLabelPropPattern(
    item: any,
    labelProp: any,
    typeLabelProp: any
  ) {
    // Case : Is a function
    if (this.typeLabelProp == 'function') {
      return this.labelProp(item);
    } else {
      // If LabelProp is a String -> item[labelProp]
      if (this.labelProp) {
        return item[this.labelProp];
      } else {
        // Default Case : return item
        return item;
      }
    }
  }
  /*  -------------------------------- 2. DATA MANAGEMENT --------------------------------  */

  /*  -------------------------------- 3. METHODS --------------------------------  */
  getFormControl(): FormControl {
    const formControl = this.field.formControl as FormControl;
    if (this.props.disabled) {
      formControl.disable();
    }
    return formControl;
  }

  /**
   *  Returns data to the user independently of the mat-option data
   * @param options List of Data from filter
   * @returns String
   */
  displayFn(options: any): (inputValue: any) => any {
    return (inputValue: any) => {
      const labelProp = this.props?.['labelProp'];
      const typeLabelProp = typeof labelProp;

      // 1. There is a default Value
      if (this.field.defaultValue) {
        // Transforms the data to return the element to be displayed to the user
        let returnValue = this.transformResponseDataWithLabelPropPattern(
          inputValue,
          labelProp,
          typeLabelProp
        );

        // If the field is filled in, update the model
        if (inputValue.id) {
          this.formControl.setValue(
            inputValue[this.props?.['valueProp'] ?? 'id'],
            { emitEvent: true }
          );
        } else {
          // Case : Update data with defaultValue ( But not relaod request like next case )
          if (options) {
            const correspondingOption = Array.isArray(options)
              ? options.find((option) => option.id === inputValue)
              : null;
            if (correspondingOption) {
              returnValue = this.transformResponseDataWithLabelPropPattern(
                correspondingOption,
                labelProp,
                typeLabelProp
              );
              return returnValue;
            }
          }
          // Case : Following the previous model update, the search will be restarted a second time using the field id, but the search must be blocked by returning the default field.
          returnValue = this.transformResponseDataWithLabelPropPattern(
            this.field.defaultValue,
            labelProp,
            typeLabelProp
          );
          return returnValue;
        }
        return returnValue;
      }
      // 2. Classic return
      else {
        if (inputValue) {
          let correspondingOption = Array.isArray(options)
            ? options.find((option) => option.id === inputValue)
            : null;

          // new correspondingOption
          if (typeof this.valueProp === 'string') {
            correspondingOption = options.find(
              (option: any) => option[this.valueProp] === inputValue
            );
          } else if (typeof this.valueProp === 'function') {
            correspondingOption = options.find(
              (option: any) => this.props?.['valueProp'](option) === inputValue
            );
          }

          // Transforms the data to return the element to be displayed to the user
          let returnValue = this.transformResponseDataWithLabelPropPattern(
            correspondingOption,
            labelProp,
            typeLabelProp
          );
          return returnValue ?? '';
        }
        return inputValue;
      }
    };
  }

  getValue(valueProp: any, value: any) {
    if (!valueProp) {
      return value;
    }
    if (typeof valueProp === 'string') {
      return value[valueProp];
    } else if (typeof valueProp === 'function') {
      return valueProp(value);
    }
    return value;
  }
  /*  -------------------------------- / 3. METHODS --------------------------------  */
}
