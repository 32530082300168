import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sample-control-checkbox',
  templateUrl: './sample-control-checkbox.component.html',
  styleUrl: './sample-control-checkbox.component.scss',
})
export class SampleControlCheckboxComponent extends FieldType {
  opts: any[] = [];
  expectedAnswer: any;
  commentOption: any;
  attachmentOption: any;
  showComment: boolean = false;
  comment: string = '';

  ngOnInit() {
    if (this.props.options) {
      if (Array.isArray(this.props.options)) {
        this.opts = this.props.options;
      } else if (this.props.options instanceof Observable) {
        this.props.options.subscribe((opts: any[]) => {
          this.opts = opts;
        });
      }
    }

    // if (!this.field.fieldGroup) {
    //   this.field.fieldGroup = [];
    // }
    // for (const option of this.opts) {
    //   this.field.fieldGroup.push({
    //     key: this.key.toString() + '_' + option.value,
    //     type: 'checkbox'
    //   })
    // }

    if (this.props['initialComment']) {
      this.showComment = true;
      this.comment = this.props['initialComment'];
    }

    this.expectedAnswer = this.props['expectedAnswer'];
    this.commentOption = this.props['configuration']['comment'];
    this.attachmentOption = this.props['configuration']['attachment'];

    if (this.commentOption && this.commentOption.is_allowed) {
      if (this.commentOption.only_if_unvalid) {
        this.formControl.valueChanges.subscribe((value: string[]) => {
          if (
            this.expectedAnswer.values &&
            this.expectedAnswer.values.length > 0 &&
            value.length > 0
          ) {
            const values: string[] = this.expectedAnswer.values;
            if (this.expectedAnswer.condition) {
              if ((this.expectedAnswer.condition = 'and')) {
                if (values.length != value.length) {
                  this.showComment = true;
                  this.comment = '';
                } else {
                  if (values.every((v) => value.includes(v))) {
                    this.showComment = false;
                    this.comment = '';
                  } else {
                    this.showComment = true;
                  }
                }
              } else if ((this.expectedAnswer.condition = 'or')) {
                if (value.length > values.length) {
                  this.showComment = true;
                } else {
                  if (values.some((v) => value.includes(v))) {
                    this.showComment = false;
                    this.comment = '';
                  } else {
                    this.showComment = true;
                  }
                }
              }
            } else {
              if (values.length == value.length) {
                if (values.every((v) => value.includes(v))) {
                  this.showComment = false;
                  this.comment = '';
                } else {
                  this.showComment = true;
                }
              } else {
                this.showComment = true;
              }
            }
          } else {
            this.showComment = false;
            this.comment = '';
          }
        });
      } else {
        this.showComment = true;
      }
    }
  }

  checkboxChange(option: any, event: any) {
    const formControl = this.getFormControl();
    let currentValue = formControl.value;
    if (!currentValue) {
      currentValue = [];
    }
    if (!Array.isArray(currentValue)) {
      currentValue = [currentValue];
    }
    if (event.checked) {
      if (!currentValue.includes(option.value)) {
        currentValue.push(option.value);
      }
      formControl.setValue(currentValue);
    } else {
      const index = currentValue.indexOf(option.value);
      if (index > -1) {
        currentValue.splice(index, 1);
      }
      formControl.setValue(currentValue);
    }
  }

  optionChecked(option: any) {
    const values = this.getFormControl().value;
    if (!values) {
      return false;
    }
    if (values.includes(option.value)) {
      return true;
    }
    return false;
  }

  getFormControl(): FormControl {
    const formControl = this.field.formControl as FormControl;
    if (this.props.disabled) {
      formControl.disable();
    }
    return formControl;
  }
}
