<mat-form-field style="width: 100%; cursor: pointer">
  <!-- <mat-label>{{ to["label"] }}</mat-label> -->
  <mat-icon matPrefix (click)="fileUpload.click()">
    <svg-icon
      [src]="icon"
      fxLayout="row"
      fxLayoutAlign="center center"
      [svgStyle]="{ 'height.px': 22, 'width.px': 22 }"
    ></svg-icon>
  </mat-icon>
  <input
    style="cursor: pointer"
    matInput
    readonly
    (click)="fileUpload.click()"
    [value]="inputText ? inputText : ''"
    [placeholder]="to.label ? (to.required ? to.label + ' *' : to.label) : ''"
  />

  <input
    type="file"
    style="display: none"
    (change)="onFileSelected($event)"
    [accept]="to['accept']"
    #fileUpload
    [multiple]="to['multiple']"
  />
  @if(files && files.length > 0){
  <mat-icon matSuffix (click)="deleteFiles()">
    <svg-icon
      src="/assets/icons/delete-light.svg"
      fxLayout="row"
      fxLayoutAlign="center center"
      [svgStyle]="{ 'height.px': 22, 'width.px': 22 }"
    ></svg-icon>
  </mat-icon>
  }
</mat-form-field>
