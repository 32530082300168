<mat-dialog-content>
  <h2 class="title-mat-card">Mise à jour des actions correctives</h2>
  <form
    [formGroup]="deviceAlertAddForm"
    (ngSubmit)="acceptDraft()"
    fxLayout="column"
    fxLayoutAlign="start strech"
    fxLayoutGap="20px"
  >
    <formly-form
      [model]="updateAlertModel"
      [fields]="deviceAlertAddFormFieldConfig"
      [form]="deviceAlertAddForm"
    ></formly-form>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <button mat-stroked-button (click)="cancel()">Annuler</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!deviceAlertAddForm.valid"
      >
        Accepter
      </button>
    </div>
  </form>
</mat-dialog-content>
