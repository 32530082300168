import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-icon-input',
  templateUrl: './icon-input.component.html',
  styleUrl: './icon-input.component.scss',
})
export class IconInputComponent extends FieldType {
  getFormControl(): FormControl {
    const formControl = this.field.formControl as FormControl;
    if (this.props.disabled) {
      formControl.disable();
    }
    return formControl;
  }
}
