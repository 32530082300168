import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MedicalDeviceAlertService } from '@app/data/services/medical-device-alert.service';
import { ConfigModel } from '@app/shared/components/custom-table/decorators/config.model';

@Component({
  selector: 'app-medical-device-alert-concerned-batches',
  templateUrl: './medical-device-alert-concerned-batches.component.html',
  styleUrl: './medical-device-alert-concerned-batches.component.scss',
})
export class MedicalDeviceAlertConcernedBatchesComponent {
  readonly dialogRef = inject(
    MatDialogRef<MedicalDeviceAlertConcernedBatchesComponent>
  );
  readonly data = inject<any>(MAT_DIALOG_DATA);

  medicalDeviceBatchesConfig: ConfigModel = {
    api: '',
    title: '',
    badge: false,
    expandOptions: { active: false, expandRowConfig: [] },
    addOptions: { active: false, label: 'Ajouter un lot' },
    editOptions: { active: false, type: 'redirect', icon: 'show-light' },
    paginator: {
      pageSizeOptions: [5, 10, 25, 50],
      pageSize: 10,
      showFirstLastButtons: true,
    },
    selectOptions: { active: false },
    searchOptions: { active: false },
    customActions: [],
    columns: [
      {
        label: 'Numéro de Lot',
        key: 'batch_number',
        type: 'string',
      },
      {
        label: 'Date de réception',
        key: 'receipt_date',
        type: 'date',
      },
      {
        label: 'Taille du lot',
        key: 'batch_size',
        type: 'string',
      },
    ],
  };

  medicalDeviceBatchesList: any = [];

  constructor(private medicalDeviceAlertService: MedicalDeviceAlertService) {
    const alert = this.data;

    if (!alert || !alert.id) {
      this.dialogRef.close();
    }

    this.medicalDeviceAlertService.findOne(alert.id).subscribe((response) => {
      console.log('Alert found: ', response);
      this.medicalDeviceBatchesList = response.medical_device_batches;
    });
  }

  close() {
    this.dialogRef.close();
  }

  getActionModalFromCustomTable($event: {
    type: string;
    data: any[];
    config?: any;
  }) {
    throw new Error('Method not implemented.');
  }
}
