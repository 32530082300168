<mat-form-field>
  <mat-label>{{ to["label"] }}</mat-label>
  <!-- type="date" -->
  <input
    matInput
    [matDatepicker]="picker"
    [min]="minDate"
    [max]="maxDate"
    [formControl]="getFormControl()"
    [formlyAttributes]="field"
    [required]="to['required'] ? true : false"
    [readonly]="to.readonly"
    (change)="onChange($event)"
  />
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <!-- (click)="french()" -->
  <mat-datepicker
    #picker
    [disabled]="to.readonly || to.disabled"
  ></mat-datepicker>
</mat-form-field>
