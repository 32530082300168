<mat-stepper labelPosition="bottom">
  <mat-step
    *ngFor="let step of field.fieldGroup; let index = index; let last = last"
    [stepControl]="getStepControl(step)"
    [completed]="step.props?.['isCompleted']"
    [state]="step.props?.['stepFieldState']"
    [aria-labelledby]=" step.props?.['stepFieldState']?'valid-step':step.props?.['stepFieldState']==false?'invalid-step':''"
  >
    <ng-template matStepLabel *ngIf="step.props"
      >{{ step.props.label }}
    </ng-template>
    <div class="step-content">
      <formly-field [field]="step"></formly-field>

      <div class="flex-btn-group">
        <button
          matStepperPrevious
          *ngIf="index === 0"
          mat-stroked-button
          type="button"
          color="primary"
          (click)="cancelForm()"
        >
          <div class="flex-btn">Annuler</div>
        </button>
        <button
          matStepperPrevious
          *ngIf="index !== 0"
          mat-stroked-button
          type="button"
          color="primary"
        >
          <div class="flex-btn">
            <svg-icon
              *ngIf="field.props && field.props['button'].previous.icon"
              [src]="
                'assets/icon/' + field.props['button'].previous.icon + '.svg'
              "
              fxLayout="row"
              fxLayoutAlign="center center"
            ></svg-icon>
            {{
              field.props && field.props["button"].previous
                ? field.props["button"].previous.label
                : "Etape précédente"
            }}
          </div>
        </button>

        <button
          matStepperNext
          *ngIf="!last"
          mat-flat-button
          color="primary"
          type="button"
          [disabled]="!isStepValid(step)"
        >
          <div class="flex-btn">
            {{
              field.props && field.props["button"].next
                ? field.props["button"].next.label
                : "Etape suivante"
            }}
            <svg-icon
              *ngIf="field.props && field.props['button'].next.icon"
              [src]="'assets/icon/' + field.props['button'].next.icon + '.svg'"
              fxLayout="row"
              fxLayoutAlign="center center"
            ></svg-icon>
          </div>
        </button>

        <button
          *ngIf="last"
          mat-flat-button
          color="primary"
          [disabled]="!form.valid"
          type="submit"
        >
          <div class="flex-btn">
            <svg-icon
              *ngIf="field.props && field.props['button'].submit.icon"
              [src]="
                'assets/icon/' + field.props['button'].submit.icon + '.svg'
              "
              fxLayout="row"
              fxLayoutAlign="center center"
            ></svg-icon>
            {{
              field.props && field.props["button"].submit
                ? field.props["button"].submit.label
                : "Valider"
            }}
          </div>
        </button>
      </div>
    </div>
  </mat-step>

  <!-- Icon overrides. -->
  <ng-template matStepperIcon="true">
    <mat-icon> check_circle </mat-icon>
  </ng-template>
  <ng-template matStepperIcon="false">
    <mat-icon> cancel </mat-icon>
  </ng-template>
  <!--/ Icon overrides. -->
</mat-stepper>
