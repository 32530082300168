<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="20px"
  class="dialog-title"
>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <svg-icon
      [src]="'assets/icons/' + getIconModal() + '.svg'"
      fxLayout="row"
      fxLayoutAlign="center center"
      class="mat-primary-color"
      [svgStyle]="{ 'width.px': 40, 'height.px': 40 }"
    ></svg-icon>
    <h3>
      {{
        data.config && data.config.header.label
          ? data.config.header.label
          : "Modifier une offre"
      }}
    </h3>
  </div>
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content
  class="mat-typography"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="20px"
>
  <form [formGroup]="dialogForm" (ngSubmit)="submitDialogForm()">
    <formly-form
      [model]="dialogModel"
      [fields]="fieldsForm"
      [options]="options"
      [form]="dialogForm"
      [ngClass]="cssClasses"
    ></formly-form>
  </form>

  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="10px"
    class="dialog-actions"
    *ngIf="data.config && data.config.actions"
  >
    <button mat-stroked-button mat-dialog-close [mat-dialog-close]="true">
      {{ data.config.actions.cancel ? data.config.actions.cancel : "Annuler" }}
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="submitDialogForm()"
      [disabled]="!dialogForm.valid"
    >
      {{ data.config.actions.submit ? data.config.actions.submit : "Valider" }}
    </button>
  </div>
</mat-dialog-content>
