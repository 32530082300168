<!-- Search Bar -->
<mat-form-field
  class="search-form-field w-100"
  (mouseenter)="showResults = true"
>
  <mat-icon matPrefix>
    <svg-icon
      src=" assets/icons/search-document-light.svg"
      fxLayout="row"
      fxLayoutAlign="center center"
      [svgStyle]="{ 'height.px': 22, 'width.px': 22 }"
    ></svg-icon
  ></mat-icon>
  <input
    type="text"
    matInput
    placeholder="Rechercher un élément ( Entrez au moins 3 caractères )"
    (keyup)="applyFilter($event)"
    [(ngModel)]="searchText"
  />
  <button
    *ngIf="searchText"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="resetSearchText()"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<!-- Results Overlay -->
<div
  class="background-overlay"
  *ngIf="searchText.length > 2 && showResults"
></div>
<div
  class="search-overlay"
  [hidden]="!showResults"
  [ngClass]="{ 'hide-margin-overlay': getResultsToShow().length == 0 }"
  (mouseleave)="showResults = false"
>
  <mat-divider></mat-divider>
  <div class="search-overlay-content">
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="0px">
      @if (findDataInSearchRequest) {
      <ng-container *ngFor="let item of getResultsToShow()">
        @if (searchResults[item].length>0) {
        <div
          fxLayout="row wrap"
          fxLayoutAlign="start start"
          fxLayoutGap="20px"
          fxFlex="50"
          class="overlay-item"
        >
          <mat-list>
            <div
              mat-subheader
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="20px"
            >
              <span>{{ getHeaderLabel(item) }}</span>

              <div
                class="table-badge mat-primary-background-pastel mat-primary-color"
                fxLayout="column"
                fxLayoutAlign="center center"
                fxLayoutGap="0px"
              >
                {{ searchResults[item].length + " résultats" }}
              </div>
              <!--
              <span class="header-count-results"
                >{{ searchResults[item].length }} résultats</span
              >
              -->
            </div>
            <mat-list-item
              *ngFor="let result of searchResults[item]"
              (click)="goToLink(item, result.id)"
              class="overlay-mat-list-item"
            >
              <svg-icon
                matListItemIcon
                [src]="'assets/icons/' + apiEndpointList[item].icon + '.svg'"
                fxLayout="row"
                fxLayoutAlign="center center"
                [svgStyle]="{ 'height.px': 24 }"
              ></svg-icon>
              <div matListItemTitle>
                {{ apiEndpointList[item].titleCell(result) }}
              </div>
              <div matListItemLine *ngIf="apiEndpointList[item].lineCell">
                {{ apiEndpointList[item].lineCell(result) }}
              </div>
            </mat-list-item>
          </mat-list>
        </div>
        }
      </ng-container>
      }@else if(searchText.length>2){
      <div class="no-data-overlay">
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          class="mat-warn-color"
        >
          <svg-icon
            src="assets/icons/search-close-light.svg"
            fxLayout="row"
            fxLayoutAlign="center center"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          ></svg-icon>
          <span
            >Nous n'avons trouvé aucun résultat pour votre recherche
            <b>«{{ searchText }}»</b></span
          >
        </div>
      </div>
      }
    </div>
  </div>
  <mat-divider></mat-divider>
</div>
