import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/data/api/api.service';
import { MedicalDeviceAlert } from '@app/data/models/medical-device-alert.model';
import { Observable } from 'rxjs';

const routes = {
  medicalDeviceAlerts: '/medical-device-alerts',
  medicalDeviceAlertsDrafts: '/medical-device-alerts/drafts',
  medicalDeviceAlert: (id: string) => `/medical-device-alerts/${id}`,
  medicalDeviceAlertFile: (id: string) => `/medical-device-alerts/${id}/file`,
};

@Injectable({
  providedIn: 'root',
})
export class MedicalDeviceAlertService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  getDeviceAlerts(id: string): Observable<MedicalDeviceAlert[]> {
    return this.apiService.get(
      `${routes.medicalDeviceAlerts}?filter.registered_device.id=${id}`
    );
  }

  getSiteDevicesAlerts(siteId: string): Observable<any> {
    return this.apiService.get(
      `${routes.medicalDeviceAlerts}?filter.registered_device.site.id=${siteId}&filter.is_draft=false`
    );
  }

  getSiteDraftDevicesAlerts(siteId: string): Observable<any> {
    return this.apiService.get(
      `${routes.medicalDeviceAlerts}?filter.registered_device.site.id=${siteId}&filter.is_draft=true`
    );
  }

  create(alert: any): Observable<MedicalDeviceAlert> {
    let alertData = {
      name: alert.name,
      sent_date: alert.sentDate,
      corrective_actions: alert.correctiveActions,
      recipient_email: alert.recipientEmail,
      registered_device: alert.registeredDevice,
      alert_date: alert.alertDate,
      origine: alert.origine,
      medical_device_batches: alert.medicalDeviceBatches,
    };
    return this.apiService.post(routes.medicalDeviceAlerts, alertData);
  }

  findOne(id: string): Observable<MedicalDeviceAlert> {
    return this.apiService.get(routes.medicalDeviceAlert(id));
  }

  update(alert: any): Observable<MedicalDeviceAlert> {
    let alertData = {
      name: alert?.name,
      sent_date: alert?.sentDate,
      corrective_actions: alert?.correctiveActions,
      recipient_email: alert?.recipientEmail,
    };
    return this.apiService.patch(
      `${routes.medicalDeviceAlerts}/${alert.id}`,
      alertData
    );
  }

  delete(id: string): Observable<MedicalDeviceAlert> {
    return this.apiService.delete(routes.medicalDeviceAlert(id));
  }

  acceptDraft(alertId: string, alert: any): Observable<MedicalDeviceAlert> {
    let alertData = {
      sent_date: alert.sentDate,
      corrective_actions: alert.correctiveActions,
      recipient_email: alert.recipientEmail,
      medical_device_batches: alert.medicalDeviceBatches,
    };
    return this.apiService.post(
      `${routes.medicalDeviceAlert(alertId)}/accept-draft`,
      alertData
    );
  }

  uploadFile(alertId: string, file: File): Observable<any> {
    // const formData = new FormData();
    const formData: any = new FormData();
    const blob = new Blob([file], { type: file.type });
    formData.append('file', blob, file.name);
    formData.append('test', 'blob');
    return this.apiService.postImage(
      routes.medicalDeviceAlertFile(alertId),
      formData
    );
  }

  downloadFile(alertId: string): Observable<any> {
    return this.apiService.getBase64(routes.medicalDeviceAlertFile(alertId));
  }
}
