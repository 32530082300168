import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-text-type',
  templateUrl: './text-type.component.html',
  styleUrl: './text-type.component.scss'
})
export class TextTypeComponent extends FieldType {

  label: string ="Entité Légale: ";
  contentText: string= ""; 
}

