import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DeleteDialog {
  config: {
    header: {
      icon?: string;
      label?: string;
    };
    content: {
      img?: string;
      title?: string;
      text?: string;
    };
    actions: {
      cancel?: string;
      submit?: string;
    };
  };
  data: any;
}

@Component({
  selector: 'delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeleteDialog,
    public dialogRef: MatDialogRef<DeleteDialogComponent>
  ) {}

  submitDialogForm() {
    this.dialogRef.close(true);
  }
}
