import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss',
})
export class DatePickerComponent extends FieldType {
  constructor(
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {
    super();
  }

  french() {
    this._locale = 'fr';
    this._adapter.setLocale(this._locale);
  }

  minDateControl = new FormControl();
  maxDateControl = new FormControl();

  minDate = new Date();
  maxDate = new Date();

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.minDateControl.setValue(this.props['minDateControl']);
    this.maxDateControl.setValue(this.props['maxDateControl']);

    this.maxDate = new Date(this.props['maxDateControl']);
    this.minDate = new Date(this.props['minDateControl']);
  }

  getFormControl(): FormControl {
    const formControl = this.field.formControl as FormControl;
    if (this.props.disabled) {
      formControl.disable();
    }
    return formControl;
  }

  onChange(event: any): void {
    const newValue = (event.target as HTMLInputElement).value;
    const dt = this.stringToDate(newValue, 'dd/mm/yyyy', '/');
    this.getFormControl().setValue(dt);
  }

  stringToDate(_date: string, _format: string, _delimiter: string) {
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf('mm');
    var dayIndex = formatItems.indexOf('dd');
    var yearIndex = formatItems.indexOf('yyyy');
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(
      dateItems[yearIndex] as any,
      month,
      dateItems[dayIndex] as any
    );
    return formatedDate;
  }
}
