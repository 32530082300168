// translation.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';


@Pipe({
    name: 'translateName'
})
export class CustomTranslatePipe implements PipeTransform {
    constructor(private cookieService: SsrCookieService) { }

    transform(name: any): string {
        const lang = this.cookieService.get('current-language') || 'fr';
        return name[lang] ? (name[lang] || name['fr']) : name;
    }
}
