import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-panel',
  template: `
    <mat-card class="mat-elevation-z0"  [style.margin-bottom]="to['fxLayoutGap']">
    <mat-card-header>
      <mat-card-title>{{ props.label }}</mat-card-title>
      <mat-card-subtitle *ngIf="props['subtitle']">{{props['subtitle']}}</mat-card-subtitle>
    </mat-card-header>
      <mat-card-content>
      <ng-container #fieldComponent></ng-container>
      </mat-card-content>
    </mat-card>
  `,
})
export class PanelWrapperComponent extends FieldWrapper { }