import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
            <div>

                <div class="flex justify-space-between align-center">
                    <h4> {{ props.label }} </h4>
                    <button mat-flat-button color="primary" (click)="add()" type="button"><mat-icon>add</mat-icon> {{ props['addText']}}</button>
                </div>

                <!-- Reverse Array -->
                <div *ngFor="let field of field.fieldGroup?.slice()?.reverse(); let i = index" class="flex relative">
                    <formly-field class="col" [field]="field" class="w-100"></formly-field>
                    <div class="btn-wrapper">
                        <button mat-icon-button color="warn" (click)="remove(i)" type="button">
                          <mat-icon>delete</mat-icon>
                        </button>
                    </div>

                </div>

            </div>
  `,
  styles: [
    `
      .flex{
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
        gap:10px;
      }
      .relative{
        position:relative;
      }
      .justify-space-between{
         justify-content:space-between;
      }
      .align-center{
        align-items:center;
      }
      .btn-wrapper{
        width:40px;
        height:40px;
        display:flex;
        justify-content:center;
        align-items:center;
        position:absolute;
        top:10px;
        right:10px;
        border: 1px solid #EAECF0;
        border-radius:50%;
      }
      .w-75{
        width:75%;
      }
      .w-100{
        width:100%;
      }
    `
  ]

})
export class RepeatTypeComponent extends FieldArrayType {

  ngOnInit(): void {
    // Add Item by default
    if (this.field.fieldGroup?.length == 0) {
      this.add();
    }
  }
}